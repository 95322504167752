.alert-container {
  z-index: 2000;
  position: fixed;
  width: 500px;
  right: 0;
  bottom: 0;
  margin-right: 20px;
  margin-bottom: 20px; }
  .alert-container .alert {
    margin: 20px 0 0 0; }
