/* You can add global styles to this file, and also import other style files */
@import url(~react-bootstrap-table-next/dist/react-bootstrap-table2.css);
:root {
  --blue: #649ABA;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #00366A;
  --secondary: #3CB5AD;
  --success: #68cc8c;
  --info: #555187;
  --warning: #F8A455;
  --danger: #FF0048;
  --light: #FAFAFA;
  --dark: #34394C;
  --breakpoint-xs: 0;
  --breakpoint-sm: 550px;
  --breakpoint-md: 700px;
  --breakpoint-lg: 1120px;
  --breakpoint-xl: 1250px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #FAFAFA; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 600; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #3CB5AD;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #297c76;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.1875rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.53125rem; }

h4, .h4 {
  font-size: 1.3125rem; }

h5, .h5 {
  font-size: 1.09375rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2em + 0.75rem);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #34394C;
    background-color: #fff;
    border-color: #34394C;
    outline: 0;
    box-shadow: 0 0 0 0; }
  .form-control::placeholder {
    color: #ccc;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 2px);
  padding-bottom: calc(0.375rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 2; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.09375rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 0.75rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 2;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 2px; }

.form-control-lg {
  height: calc(1.5em + 1rem + 4px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #68cc8c; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(104, 204, 140, 0.9);
  border-radius: 2px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #68cc8c;
  padding-right: calc(2em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2368cc8c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.5em + 0.1875rem) center;
  background-size: calc(1em + 0.375rem) calc(1em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #68cc8c;
    box-shadow: 0 0 0 2px rgba(104, 204, 140, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(2em + 0.75rem);
  background-position: top calc(0.5em + 0.1875rem) right calc(0.5em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #68cc8c;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2368cc8c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(1em + 0.375rem) calc(1em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #68cc8c;
    box-shadow: 0 0 0 2px rgba(104, 204, 140, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #68cc8c; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #68cc8c; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #68cc8c; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #8ed9a9;
  background-color: #8ed9a9; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgba(104, 204, 140, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #68cc8c; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #68cc8c; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #68cc8c;
  box-shadow: 0 0 0 2px rgba(104, 204, 140, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #FF0048; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(255, 0, 72, 0.9);
  border-radius: 2px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #FF0048;
  padding-right: calc(2em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF0048' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF0048' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.5em + 0.1875rem) center;
  background-size: calc(1em + 0.375rem) calc(1em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #FF0048;
    box-shadow: 0 0 0 2px rgba(255, 0, 72, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(2em + 0.75rem);
  background-position: top calc(0.5em + 0.1875rem) right calc(0.5em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #FF0048;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF0048' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF0048' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(1em + 0.375rem) calc(1em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #FF0048;
    box-shadow: 0 0 0 2px rgba(255, 0, 72, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #FF0048; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #FF0048; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #FF0048; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff336d;
  background-color: #ff336d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgba(255, 0, 72, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #FF0048; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #FF0048; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #FF0048;
  box-shadow: 0 0 0 2px rgba(255, 0, 72, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 550px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 8px 20px;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #00366A;
  border-color: #00366A; }
  .btn-primary:hover {
    color: #fff;
    background-color: #002344;
    border-color: #001c37; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #002344;
    border-color: #001c37;
    box-shadow: 0 0 0 0 rgba(38, 84, 128, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #00366A;
    border-color: #00366A; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #001c37;
    border-color: #00162a; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(38, 84, 128, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #3CB5AD;
  border-color: #3CB5AD; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #329892;
    border-color: #2f8f88; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #329892;
    border-color: #2f8f88;
    box-shadow: 0 0 0 0 rgba(89, 192, 185, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #3CB5AD;
    border-color: #3CB5AD; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #2f8f88;
    border-color: #2c857f; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(89, 192, 185, 0.5); }

.btn-success {
  color: #212529;
  background-color: #68cc8c;
  border-color: #68cc8c; }
  .btn-success:hover {
    color: #212529;
    background-color: #4bc276;
    border-color: #42bf6f; }
  .btn-success:focus, .btn-success.focus {
    color: #212529;
    background-color: #4bc276;
    border-color: #42bf6f;
    box-shadow: 0 0 0 0 rgba(93, 179, 125, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #68cc8c;
    border-color: #68cc8c; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #42bf6f;
    border-color: #3eb769; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(93, 179, 125, 0.5); }

.btn-info {
  color: #fff;
  background-color: #555187;
  border-color: #555187; }
  .btn-info:hover {
    color: #fff;
    background-color: #46436f;
    border-color: #413e67; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #46436f;
    border-color: #413e67;
    box-shadow: 0 0 0 0 rgba(111, 107, 153, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #555187;
    border-color: #555187; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #413e67;
    border-color: #3c395f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(111, 107, 153, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #F8A455;
  border-color: #F8A455; }
  .btn-warning:hover {
    color: #212529;
    background-color: #f69030;
    border-color: #f68a24; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #f69030;
    border-color: #f68a24;
    box-shadow: 0 0 0 0 rgba(216, 145, 78, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #F8A455;
    border-color: #F8A455; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #f68a24;
    border-color: #f58318; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(216, 145, 78, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #FF0048;
  border-color: #FF0048; }
  .btn-danger:hover {
    color: #fff;
    background-color: #d9003d;
    border-color: #cc003a; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #d9003d;
    border-color: #cc003a;
    box-shadow: 0 0 0 0 rgba(255, 38, 99, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #FF0048;
    border-color: #FF0048; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #cc003a;
    border-color: #bf0036; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 38, 99, 0.5); }

.btn-light {
  color: #212529;
  background-color: #FAFAFA;
  border-color: #FAFAFA; }
  .btn-light:hover {
    color: #212529;
    background-color: #e7e7e7;
    border-color: #e1e1e1; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e7e7e7;
    border-color: #e1e1e1;
    box-shadow: 0 0 0 0 rgba(217, 218, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #FAFAFA;
    border-color: #FAFAFA; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e1e1e1;
    border-color: #dadada; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(217, 218, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #34394C;
  border-color: #34394C; }
  .btn-dark:hover {
    color: #fff;
    background-color: #242835;
    border-color: #1f222e; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #242835;
    border-color: #1f222e;
    box-shadow: 0 0 0 0 rgba(82, 87, 103, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #34394C;
    border-color: #34394C; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1f222e;
    border-color: #1a1d26; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(82, 87, 103, 0.5); }

.btn-outline-primary {
  color: #00366A;
  border-color: #00366A; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #00366A;
    border-color: #00366A; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(0, 54, 106, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #00366A;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #00366A;
    border-color: #00366A; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(0, 54, 106, 0.5); }

.btn-outline-secondary {
  color: #3CB5AD;
  border-color: #3CB5AD; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #3CB5AD;
    border-color: #3CB5AD; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(60, 181, 173, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #3CB5AD;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #3CB5AD;
    border-color: #3CB5AD; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(60, 181, 173, 0.5); }

.btn-outline-success {
  color: #68cc8c;
  border-color: #68cc8c; }
  .btn-outline-success:hover {
    color: #212529;
    background-color: #68cc8c;
    border-color: #68cc8c; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(104, 204, 140, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #68cc8c;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #68cc8c;
    border-color: #68cc8c; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(104, 204, 140, 0.5); }

.btn-outline-info {
  color: #555187;
  border-color: #555187; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #555187;
    border-color: #555187; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(85, 81, 135, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #555187;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #555187;
    border-color: #555187; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(85, 81, 135, 0.5); }

.btn-outline-warning {
  color: #F8A455;
  border-color: #F8A455; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #F8A455;
    border-color: #F8A455; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(248, 164, 85, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #F8A455;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #F8A455;
    border-color: #F8A455; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(248, 164, 85, 0.5); }

.btn-outline-danger {
  color: #FF0048;
  border-color: #FF0048; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #FF0048;
    border-color: #FF0048; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(255, 0, 72, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #FF0048;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #FF0048;
    border-color: #FF0048; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 0, 72, 0.5); }

.btn-outline-light {
  color: #FAFAFA;
  border-color: #FAFAFA; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #FAFAFA;
    border-color: #FAFAFA; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(250, 250, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #FAFAFA;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #FAFAFA;
    border-color: #FAFAFA; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(250, 250, 250, 0.5); }

.btn-outline-dark {
  color: #34394C;
  border-color: #34394C; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #34394C;
    border-color: #34394C; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(52, 57, 76, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #34394C;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #34394C;
    border-color: #34394C; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(52, 57, 76, 0.5); }

.btn-link {
  font-weight: 400;
  color: #3CB5AD;
  text-decoration: none; }
  .btn-link:hover {
    color: #297c76;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.4rem 1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 2px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 550px) {
  .container, .container-sm {
    max-width: 520px; } }

@media (min-width: 700px) {
  .container, .container-sm, .container-md {
    max-width: 680px; } }

@media (min-width: 1120px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1140px; } }

@media (min-width: 1250px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1400px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1401px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 550px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 700px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1120px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1250px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8c7d5; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7a96b2; }

.table-hover .table-primary:hover {
  background-color: #a8bacc; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a8bacc; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c8eae8; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #9ad9d4; }

.table-hover .table-secondary:hover {
  background-color: #b6e3e0; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #b6e3e0; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d5f1df; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b0e4c3; }

.table-hover .table-success:hover {
  background-color: #c2ebd0; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #c2ebd0; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #cfcedd; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #a7a5c1; }

.table-hover .table-info:hover {
  background-color: #c0bfd3; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #c0bfd3; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fde6cf; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fbd0a7; }

.table-hover .table-warning:hover {
  background-color: #fcd9b7; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fcd9b7; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffb8cc; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ff7aa0; }

.table-hover .table-danger:hover {
  background-color: #ff9fba; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ff9fba; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfc; }

.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8cd; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9598a2; }

.table-hover .table-dark:hover {
  background-color: #b8bbc1; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b8bbc1; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 549.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 699.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1119.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1249.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 550px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 700px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1120px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1250px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #00366A; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 15px;
  padding-left: 15px; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -2px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -2px; }

.input-group-prepend {
  margin-right: -2px; }

.input-group-append {
  margin-left: -2px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 2px solid #ced4da;
  border-radius: 2px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 4px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 4px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 2px; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00366A;
    background-color: #00366A; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #34394C; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: dodgerblue;
    border-color: dodgerblue; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 2px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 2px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #00366A;
  background-color: #00366A; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 54, 106, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 54, 106, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 54, 106, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 4px);
    left: calc(-2.25rem + 4px);
    width: calc(1rem - 8px);
    height: calc(1rem - 8px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 54, 106, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2em + 0.75rem);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 2px solid #ced4da;
  border-radius: 2px;
  appearance: none; }
  .custom-select:focus {
    border-color: #34394C;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(0, 54, 106, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.75rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 4px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2em + 0.75rem);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2em + 0.75rem);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #34394C;
    box-shadow: 0 0 0 0; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2em + 0.75rem);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 2;
  color: #495057;
  background-color: #fff;
  border: 2px solid #ced4da;
  border-radius: 2px; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(2em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 2;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 2px 2px 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 4px);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FAFAFA, 0 0 0 0; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FAFAFA, 0 0 0 0; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FAFAFA, 0 0 0 0; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #00366A;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: dodgerblue; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #00366A;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: dodgerblue; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 2px;
    margin-left: 2px;
    background-color: #00366A;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: dodgerblue; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #FAFAFA;
    border-color: #dee2e6 #dee2e6 #FAFAFA; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 2px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #00366A; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 549.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 550px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 699.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 700px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1119.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1120px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1249.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1250px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1399.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 2px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 1px;
      border-top-right-radius: 1px; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 1px;
      border-bottom-left-radius: 1px; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 1px 1px 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 1px 1px; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 1px; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 550px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 550px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 550px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 2px; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 2px; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3CB5AD;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #297c76;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00366A;
  border-color: #00366A; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #00366A; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #001c37; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(0, 54, 106, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #3CB5AD; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #2f8f88; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(60, 181, 173, 0.5); }

.badge-success {
  color: #212529;
  background-color: #68cc8c; }
  a.badge-success:hover, a.badge-success:focus {
    color: #212529;
    background-color: #42bf6f; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(104, 204, 140, 0.5); }

.badge-info {
  color: #fff;
  background-color: #555187; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #413e67; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(85, 81, 135, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #F8A455; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #f68a24; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(248, 164, 85, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #FF0048; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #cc003a; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 0, 72, 0.5); }

.badge-light {
  color: #212529;
  background-color: #FAFAFA; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #e1e1e1; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 250, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #34394C; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1f222e; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(52, 57, 76, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 550px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 2px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 600; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #001c37;
  background-color: #ccd7e1;
  border-color: #b8c7d5; }
  .alert-primary hr {
    border-top-color: #a8bacc; }
  .alert-primary .alert-link {
    color: #000204; }

.alert-secondary {
  color: #1f5e5a;
  background-color: #d8f0ef;
  border-color: #c8eae8; }
  .alert-secondary hr {
    border-top-color: #b6e3e0; }
  .alert-secondary .alert-link {
    color: #123835; }

.alert-success {
  color: #366a49;
  background-color: #e1f5e8;
  border-color: #d5f1df; }
  .alert-success hr {
    border-top-color: #c2ebd0; }
  .alert-success .alert-link {
    color: #254832; }

.alert-info {
  color: #2c2a46;
  background-color: #dddce7;
  border-color: #cfcedd; }
  .alert-info hr {
    border-top-color: #c0bfd3; }
  .alert-info .alert-link {
    color: #181726; }

.alert-warning {
  color: #81552c;
  background-color: #feeddd;
  border-color: #fde6cf; }
  .alert-warning hr {
    border-top-color: #fcd9b7; }
  .alert-warning .alert-link {
    color: #5b3c1f; }

.alert-danger {
  color: #850025;
  background-color: #ffccda;
  border-color: #ffb8cc; }
  .alert-danger hr {
    border-top-color: #ff9fba; }
  .alert-danger .alert-link {
    color: #520017; }

.alert-light {
  color: #828282;
  background-color: #fefefe;
  border-color: #fefefe; }
  .alert-light hr {
    border-top-color: #f1f1f1; }
  .alert-light .alert-link {
    color: dimgray; }

.alert-dark {
  color: #1b1e28;
  background-color: #d6d7db;
  border-color: #c6c8cd; }
  .alert-dark hr {
    border-top-color: #b8bbc1; }
  .alert-dark .alert-link {
    color: #06070a; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 2px; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #00366A;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 2px; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #00366A;
    border-color: #00366A; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 2px;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 2px;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 550px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 2px;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 2px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 700px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 2px;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 2px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1120px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 2px;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 2px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1250px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 2px;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 2px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 2px;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 2px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #001c37;
  background-color: #b8c7d5; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #001c37;
    background-color: #a8bacc; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #001c37;
    border-color: #001c37; }

.list-group-item-secondary {
  color: #1f5e5a;
  background-color: #c8eae8; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #1f5e5a;
    background-color: #b6e3e0; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #1f5e5a;
    border-color: #1f5e5a; }

.list-group-item-success {
  color: #366a49;
  background-color: #d5f1df; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #366a49;
    background-color: #c2ebd0; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #366a49;
    border-color: #366a49; }

.list-group-item-info {
  color: #2c2a46;
  background-color: #cfcedd; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #2c2a46;
    background-color: #c0bfd3; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #2c2a46;
    border-color: #2c2a46; }

.list-group-item-warning {
  color: #81552c;
  background-color: #fde6cf; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #81552c;
    background-color: #fcd9b7; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #81552c;
    border-color: #81552c; }

.list-group-item-danger {
  color: #850025;
  background-color: #ffb8cc; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #850025;
    background-color: #ff9fba; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #850025;
    border-color: #850025; }

.list-group-item-light {
  color: #828282;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #828282;
    background-color: #f1f1f1; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #828282;
    border-color: #828282; }

.list-group-item-dark {
  color: #1b1e28;
  background-color: #c6c8cd; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e28;
    background-color: #b8bbc1; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e28;
    border-color: #1b1e28; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 550px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1120px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1250px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 2px; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #00366A !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #001c37 !important; }

.bg-secondary {
  background-color: #3CB5AD !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #2f8f88 !important; }

.bg-success {
  background-color: #68cc8c !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #42bf6f !important; }

.bg-info {
  background-color: #555187 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #413e67 !important; }

.bg-warning {
  background-color: #F8A455 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f68a24 !important; }

.bg-danger {
  background-color: #FF0048 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #cc003a !important; }

.bg-light {
  background-color: #FAFAFA !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e1e1e1 !important; }

.bg-dark {
  background-color: #34394C !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1f222e !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #00366A !important; }

.border-secondary {
  border-color: #3CB5AD !important; }

.border-success {
  border-color: #68cc8c !important; }

.border-info {
  border-color: #555187 !important; }

.border-warning {
  border-color: #F8A455 !important; }

.border-danger {
  border-color: #FF0048 !important; }

.border-light {
  border-color: #FAFAFA !important; }

.border-dark {
  border-color: #34394C !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 2px !important; }

.rounded {
  border-radius: 2px !important; }

.rounded-top {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important; }

.rounded-right {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important; }

.rounded-bottom {
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 2px !important; }

.rounded-left {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 550px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 700px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1120px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1250px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 550px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 700px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1120px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1250px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 550px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 700px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1120px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1250px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 550px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 700px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1120px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1250px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 550px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 700px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1120px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1250px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 600 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #00366A !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #000f1e !important; }

.text-secondary {
  color: #3CB5AD !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #297c76 !important; }

.text-success {
  color: #68cc8c !important; }

a.text-success:hover, a.text-success:focus {
  color: #3aad64 !important; }

.text-info {
  color: #555187 !important; }

a.text-info:hover, a.text-info:focus {
  color: #373457 !important; }

.text-warning {
  color: #F8A455 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #f57d0c !important; }

.text-danger {
  color: #FF0048 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #b30032 !important; }

.text-light {
  color: #FAFAFA !important; }

a.text-light:hover, a.text-light:focus {
  color: #d4d4d4 !important; }

.text-dark {
  color: #34394C !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #15171f !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1120px !important; }
  .container {
    min-width: 1120px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.bg-light-gray {
  background: #ced4da; }

.bg-gray {
  background: #5E6478; }

.bg-blue {
  background: #649ABA; }

/*.bg-light-gray-2 {
    background: $light-gray-2;
}*/
.bg-secondary-gradient {
  background: linear-gradient(90deg, #3CB5AD 0%, #43b9b238 90%); }

.bg-light-gray-2 {
  border-radius: 2px;
  background: #ced4da30;
  border-bottom: none; }

button.bg-light-gray-2:hover {
  background: #ced4da; }

.border-light-gray {
  border: 2px solid #ced4da; }

.border-light-gray-1px {
  border: 1px solid #ced4da; }

.border-bottom-light-gray {
  border-bottom: 1px solid #ced4da; }

.border-bottom {
  border-bottom: 1px solid #5E6478; }

.pointer {
  cursor: pointer; }

.no-wrap {
  white-space: nowrap; }

.pl-2rem {
  padding-left: 2rem; }

.title-container {
  padding: 12px 0;
  display: flex;
  align-items: center; }

.opacity-05 {
  opacity: 0.5; }

.row-hover {
  border-radius: 2px;
  background: #ced4da30; }

.row-hover-light-gray:hover {
  background: #ced4da45; }

.font-size-13 {
  font-size: 13px; }

.font-12 {
  font-size: 12px; }

.rent-contract-checkbox-position {
  padding-left: 200px !important;
  align-items: flex-start !important; }
  @media (max-width: 1119.98px) {
    .rent-contract-checkbox-position {
      padding-left: 0 !important; } }

.w-200 {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important; }
  @media (max-width: 1119.98px) {
    .w-200 {
      width: 150px !important;
      min-width: 150px !important;
      max-width: 150px !important; } }

.w-150 {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important; }

.w-140 {
  width: 140px; }
  .w-140.w-min-140 {
    min-width: 140px; }

.w-80 {
  width: 80px !important; }
  .w-80.w-min-80 {
    min-width: 80px; }

.w-100px {
  width: 100px;
  min-width: 100px; }
  @media (max-width: 699.98px) {
    .w-100px {
      width: auto;
      min-width: auto; } }

.w-lg-140px {
  width: 140px;
  min-width: 140px; }
  @media (max-width: 1119.98px) {
    .w-lg-140px {
      width: auto;
      min-width: auto; } }

@media (min-width: 700px) {
  .w-md-100 {
    width: 100% !important; } }

@media (min-width: 1250px) {
  .container-xl-max-width {
    max-width: 1400px; } }

@media (max-width: 1119.98px) {
  .font-md-14 {
    font-size: 14px; } }

.alert {
  width: 100%;
  color: black; }
  .alert.alert-info {
    background-color: #dddce770;
    border-color: transparent;
    border-left: 3px solid #555187; }
  .alert.alert-danger {
    background-color: #ff004812;
    border-color: transparent;
    border-left: 3px solid #FF0048; }
  .alert.alert-secondary {
    background-color: #3cb5ad1f;
    border-color: transparent;
    border-left: 3px solid #3CB5AD; }
  .alert.alert-warning {
    background-color: #f8a45526;
    border-color: transparent;
    border-left: 3px solid #F8A455; }

.alert-sync {
  width: 100%;
  height: 100px;
  color: black; }
  .alert-sync.alert-danger {
    background-color: #FBBBB9;
    border-left: 3px solid #FF0048; }
  .alert-sync.alert-success {
    background-color: #104a8e;
    border-color: transparent;
    border-left: 3px solid #68cc8c; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.file-input {
  border: 2px dashed #ced4da;
  height: 100px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px; }
  .file-input h6 {
    color: #ced4da; }
  .file-input .btn {
    padding-top: 0;
    padding-bottom: 0; }

.calendar-input {
  background-image: url(../gfx/icons/general/calendar.svg), linear-gradient(white, white);
  background-size: 20px, 100%;
  background-repeat: no-repeat;
  background-position: right 6px center, center; }

.input-required {
  height: 5px;
  width: 5px;
  background: #FF0048;
  position: absolute;
  border-radius: 50%; }

.form-control {
  border: 2px solid #ced4da;
  height: calc(1.8em + 0.8rem + 2px); }
  .form-control .sync {
    margin-left: 10px; }

.form-control-sm {
  height: calc(1.8em + 0.7rem); }

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  box-shadow: 0 0 0 0; }

.input-marked {
  background: #82d5d0; }
  .input-marked:focus {
    background: #a8e2de; }

.btn.btn-link {
  text-decoration: underline;
  color: #3CB5AD;
  background: transparent; }
  .btn.btn-link:hover {
    color: #297c76; }
  .btn.btn-link.btn-title-link {
    color: #34394C;
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center; }
    .btn.btn-link.btn-title-link.arrow-up span {
      transform: rotate(180deg); }
    .btn.btn-link.btn-title-link.arrow-down span {
      transform: rotate(0); }

.btn.btn-link-primary {
  color: #00366A; }

.btn.btn-table-inline {
  padding-left: 0;
  padding-right: 0; }

.btn.btn-dark {
  color: white !important; }

.btn-radio {
  min-width: 0 !important;
  width: 100% !important;
  max-width: 100% !important; }
  .btn-radio:first-child {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .btn-radio:last-child {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important; }

.btn-fixed-size-140px {
  min-width: 140px;
  width: 140px;
  max-width: 140px; }

.btn-icon-only {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none; }
  .btn-icon-only .add-hover {
    top: -1px;
    left: 0;
    height: 40px;
    width: 40px;
    position: absolute;
    background: #cccccc00;
    border-radius: 50%;
    transform: scale(0);
    transition: all 0.2s ease-in-out; }
  .btn-icon-only:hover {
    background-color: transparent;
    border: 0; }
    .btn-icon-only:hover .add-hover {
      top: -1px;
      left: 0;
      background-color: #cccccc4e;
      transition: all 0.2s ease-in-out;
      transform: scale(1); }
  .btn-icon-only:focus {
    box-shadow: none; }

.btn-fixed-size {
  height: 32px;
  width: 32px; }

.btn-fixed-size-bigger {
  height: 38px;
  width: 38px; }

.submenu {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .submenu .submenu-hover {
    height: 40px;
    width: 40px;
    position: absolute;
    background: #cccccc00;
    border-radius: 50%;
    transform: scale(0);
    transition: all 0.2s ease-in-out; }
  .submenu .btn {
    padding: 0;
    line-height: 0.9;
    border: none;
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    display: flex; }
    .submenu .btn:focus {
      box-shadow: 0 0 0 0; }
  .submenu:hover .submenu-hover {
    background-color: #cccccc4e;
    transition: all 0.2s ease-in-out;
    transform: scale(1); }
  .submenu.submenu-btn-in-table {
    position: relative;
    right: 0; }
  .submenu.submenu-position-in-nav {
    position: absolute;
    right: 22px; }

.dropdown-menu__signing {
  min-width: 9rem; }
  .dropdown-menu__signing li {
    margin-left: 0 !important; }
    .dropdown-menu__signing li .dropdown-item {
      position: relative;
      cursor: pointer; }
      .dropdown-menu__signing li .dropdown-item.item {
        height: 50px; }
        .dropdown-menu__signing li .dropdown-item.item:before {
          position: absolute;
          content: "";
          width: 100px;
          height: 30px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        .dropdown-menu__signing li .dropdown-item.item.id-card::before {
          background: url("../gfx/icons/login-btn-icons/id-card.svg") no-repeat;
          background-size: auto;
          background-position-x: 0;
          background-position-y: 50%; }
        .dropdown-menu__signing li .dropdown-item.item.smart-id::before {
          background: url("../gfx/icons/login-btn-icons/smart-id.svg") no-repeat;
          background-size: auto;
          background-position-x: 0;
          background-position-y: 50%; }
        .dropdown-menu__signing li .dropdown-item.item.mobile-id::before {
          background: url("../gfx/icons/login-btn-icons/mobile-id.svg") no-repeat;
          background-size: auto;
          background-position-x: 0;
          background-position-y: 50%; }
  .dropdown-menu__signing.dropdown-align-in-table {
    margin-right: -57px;
    margin-top: 25px; }

.btn-dropdown.dropdown-toggle .btn-title {
  margin-right: 22px; }

.btn-dropdown.dropdown-toggle .caret {
  height: 24px;
  width: 24px;
  position: absolute;
  right: 12px;
  transition: 0.25s;
  top: 6px; }
  .btn-dropdown.dropdown-toggle .caret::before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    top: 0;
    left: 0;
    mask: url("../gfx/icons/arrow_down-24px-white.svg") no-repeat;
    background-color: white;
    background-size: 24px;
    translate: transform 0.2s; }
  .btn-dropdown.dropdown-toggle .caret.caret-primary::before {
    background-color: #00366A;
    transition: background-color 0.15s ease-in-out; }

.btn-dropdown.dropdown-toggle.btn-sm .caret, .btn-group-sm > .btn-dropdown.dropdown-toggle.btn .caret {
  top: 3px; }

.btn-dropdown.dropdown-toggle:after {
  display: none; }

.btn-dropdownbutton:focus {
  background: #ccc; }

.btn-dropdown.btn-outline-primary:hover .caret.caret-primary::before {
  background-color: white; }

.btn-dropdown.btn-outline-primary.focus .caret.caret-primary::before {
  background-color: white; }

.btn-dropdown.btn-outline-primary.caret-primary::before {
  background-color: #00366A; }

.btn-group.show .caret {
  transform: rotate(180deg);
  transition: 0.25s; }
  .btn-group.show .caret.caret-primary::before {
    background-color: white; }

.btn-group.show .hover-icon-white .icon:before {
  background-color: white; }

.btn-group .btn.active {
  z-index: initial !important; }

button:focus {
  outline: none !important; }

.hover-icon-white {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .hover-icon-white:hover .icon:before {
    background-color: white; }
  .hover-icon-white:focus .icon:before {
    background-color: white; }

.badge {
  font-weight: 400;
  text-transform: uppercase;
  padding: 6px 0;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.6px;
  font-family: "Source Sans Pro";
  border-radius: 2px;
  width: 105px;
  color: white !important;
  cursor: default; }
  .badge.badge-submitted {
    background: #4B5370;
    border: 1px solid #4B5370; }
  .badge.badge-confirmed {
    background-color: #649ABA;
    border: 1px solid #649ABA; }
  .badge.badge-doing {
    background: linear-gradient(90deg, #3CB5AD 0%, #43b9b238 90%); }
  .badge.badge-done {
    background: #3CB5AD;
    border: 1px solid #3CB5AD; }
  .badge.badge-accepted {
    background-color: #ced4da30;
    color: #5E6478 !important;
    border: 1px solid transparent; }
  .badge.badge-archived {
    background-color: transparent;
    color: #5E6478 !important;
    border: 1px solid #ced4da30; }
  .badge.badge-extended {
    background-color: #F8A455;
    color: white !important;
    border: 1px solid transparent; }
  .badge.badge-rejected {
    background-color: transparent;
    color: #FF0048 !important;
    border: 1px solid #FF0048; }
  .badge.badge-primary {
    background-color: #00366A;
    color: white !important;
    border: 1px solid #00366A; }
  .badge.badge-gray {
    background-color: #5E6478;
    color: white !important;
    border: 1px solid #5E6478; }
  .badge.badge-light-gray-2 {
    background-color: #ced4da;
    color: white !important;
    border: 1px solid #ced4da; }
  .badge.badge-outline-success {
    background-color: transparent;
    color: #68cc8c !important;
    border: 1px solid #68cc8c;
    font-weight: 600; }
  .badge.badge-outline-danger {
    background-color: transparent;
    color: #FF0048 !important;
    border: 1px solid #FF0048;
    font-weight: 600; }
  .badge.badge-lg {
    width: 140px; }
  .badge.badge-xl {
    width: 200px; }
  .badge.badge-messages {
    padding: 2px; }
  .badge.badge-danger {
    background-color: #FF0048;
    color: white !important;
    border: 1px solid #FF0048;
    text-transform: lowercase;
    width: 98px;
    font-size: 14px; }
  .badge.badge-label {
    box-shadow: 0 0 16px #88888840;
    width: auto;
    cursor: auto; }
  .badge.badge-unselected {
    background: white;
    color: #5E6478 !important; }
  .badge.badge-blue {
    background: #3E7FCA;
    color: white !important; }
  .badge.badge-green {
    background: #00ce9e;
    color: white !important; }
  .badge.badge-purple {
    background: #a84ed5;
    color: white !important; }
  .badge.badge-pink {
    background: #d54e88;
    color: white !important; }
  .badge.badge-rkas-pink {
    background: #E46C8A;
    color: white !important;
    border: 1px solid #E46C8A; }
  .badge.badge-rkas-success {
    background: #68cc8c;
    color: white !important;
    border: 1px solid #68cc8c; }
  .badge.badge-orange {
    background: orange;
    color: white !important; }
  .badge.badge-light-gray {
    background: #99afc3;
    color: white !important; }
  .badge.cursor-pointer {
    cursor: pointer; }
  .badge.badge-input-disabled {
    padding-left: 6px;
    padding-right: 6px;
    width: auto;
    color: #34394C !important; }

.badge-container {
  display: grid;
  width: 100%;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr 1fr 1fr; }

.messages-label {
  height: 20px;
  width: 20px;
  font-size: 14px;
  color: #5E6478;
  background: transparent;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px; }
  .messages-label.message-label-position {
    margin-bottom: -20px;
    margin-top: -20px; }
  .messages-label.active {
    color: #34394C;
    background: #ced4da; }
  .messages-label.messages-unread {
    color: white;
    background: #FF0048; }

.badge-outline-rkas-pink {
  box-shadow: 2px 2px 0 #E46C8A inset, -2px -2px 0 #E46C8A inset;
  color: #E46C8A !important; }

.badge-outline-rkas-success {
  box-shadow: 2px 2px 0 #68cc8c inset, -2px -2px 0 #68cc8c inset;
  color: #68cc8c !important; }

.badge-outline-confirmed {
  box-shadow: 2px 2px 0 #649ABA inset, -2px -2px 0 #649ABA inset;
  color: #649ABA !important; }

.badge-outline-extended {
  box-shadow: 2px 2px 0 #F8A455 inset, -2px -2px 0 #F8A455 inset;
  color: #F8A455 !important; }

.badge-outline-done {
  box-shadow: 2px 2px 0 #3CB5AD inset, -2px -2px 0 #3CB5AD inset;
  color: #3CB5AD !important; }

.badge-outline-submitted {
  box-shadow: 2px 2px 0 #4B5370 inset, -2px -2px 0 #4B5370 inset;
  color: #4B5370 !important; }

.badge-outline-gray {
  box-shadow: 2px 2px 0 #5E6478 inset, -2px -2px 0 #5E6478 inset;
  color: #5E6478 !important; }

.btn-badge {
  background: transparent; }
  .btn-badge:focus {
    outline: 0;
    box-shadow: none; }
  .btn-badge:hover {
    background: transparent;
    border: 1px solid transparent; }

@media (max-width: 699.98px) {
  .badge-container {
    display: grid;
    width: 100%;
    grid-gap: 14px;
    grid-template-columns: 1fr 1fr; } }

@font-face {
  font-family: "data-table";
  src: url("../fonts/data-table.eot");
  src: url("../fonts/data-table.eot?#iefix") format("embedded-opentype"), url("../fonts/data-table.woff") format("woff"), url("../fonts/data-table.ttf") format("truetype"), url("../fonts/data-table.svg#data-table") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "data-table" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="datatable-icon-"]:before,
[class*=" datatable-icon-"]:before {
  font-family: "data-table" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.datatable-icon-filter:before {
  content: "\62"; }

.datatable-icon-collapse:before {
  content: "\61"; }

.datatable-icon-expand:before {
  content: "\63"; }

.datatable-icon-close:before {
  content: "\64"; }

.datatable-icon-up:before {
  content: "\65"; }

.datatable-icon-down:before {
  content: "\66"; }

.datatable-icon-sort:before {
  content: "\67"; }

.datatable-icon-done:before {
  content: "\68"; }

.datatable-icon-done-all:before {
  content: "\69"; }

.datatable-icon-search:before {
  content: "\6a"; }

.datatable-icon-pin:before {
  content: "\6b"; }

.datatable-icon-add:before {
  content: "\6d"; }

.datatable-icon-left:before {
  content: "\6f"; }

.datatable-icon-right:before {
  content: "\70"; }

.datatable-icon-skip:before {
  content: "\71"; }

.datatable-icon-prev:before {
  content: "\72"; }

.datatable {
  width: 100% !important; }
  .datatable .react-bootstrap-table .table {
    font-size: inherit;
    table-layout: auto;
    margin-bottom: 0; }
    .datatable .react-bootstrap-table .table thead th {
      color: #868A97;
      font-weight: 400;
      font-size: 13px;
      padding: 0 28px 0 10px;
      border-bottom: 1px solid #E4E4E4;
      border-left: 1px solid #E4E4E4;
      border-top: 1px solid #E4E4E4;
      white-space: nowrap; }
    .datatable .react-bootstrap-table .table thead .datatable-header-cell {
      position: relative;
      display: inline-block;
      height: 40px; }
      .datatable .react-bootstrap-table .table thead .datatable-header-cell .datatable-header-cell-template-wrap {
        display: flex;
        align-items: center;
        height: 100%; }
      .datatable .react-bootstrap-table .table thead .datatable-header-cell.sortable .sort-btn {
        margin-top: 5px; }
        .datatable .react-bootstrap-table .table thead .datatable-header-cell.sortable .sort-btn::before, .datatable .react-bootstrap-table .table thead .datatable-header-cell.sortable .sort-btn::after {
          content: "";
          width: 15px;
          height: 20px;
          position: absolute;
          margin-left: 7px;
          background-position: top; }
        .datatable .react-bootstrap-table .table thead .datatable-header-cell.sortable .sort-btn::after {
          top: 0;
          transform: rotate(180deg);
          background: url("../gfx/icons/arrows/arrow_down_gray.svg") no-repeat;
          background-size: 12px;
          margin-left: 4px; }
        .datatable .react-bootstrap-table .table thead .datatable-header-cell.sortable .sort-btn::before {
          background: url("../gfx/icons/arrows/arrow_down_gray.svg") no-repeat;
          background-size: 12px; }
        .datatable .react-bootstrap-table .table thead .datatable-header-cell.sortable .sort-btn.sort-asc::after {
          background: url("../gfx/icons/arrows/arrow_down_dark.svg") no-repeat;
          background-size: 12px;
          margin-left: 4px; }
        .datatable .react-bootstrap-table .table thead .datatable-header-cell.sortable .sort-btn.sort-asc::before {
          background: url("../gfx/icons/arrows/arrow_down_gray.svg") no-repeat;
          background-size: 12px; }
        .datatable .react-bootstrap-table .table thead .datatable-header-cell.sortable .sort-btn.sort-desc::after {
          background: url("../gfx/icons/arrows/arrow_down_gray.svg") no-repeat;
          background-size: 12px;
          margin-left: 4px; }
        .datatable .react-bootstrap-table .table thead .datatable-header-cell.sortable .sort-btn.sort-desc::before {
          background: url("../gfx/icons/arrows/arrow_down_dark.svg") no-repeat;
          background-size: 12px; }
      .datatable .react-bootstrap-table .table thead .datatable-header-cell.sort-active .datatable-header-cell-label {
        transition: 0.2s;
        font-weight: 400;
        color: #00366A; }
    .datatable .react-bootstrap-table .table.table-condensed tbody td {
      padding: 0 4px;
      height: 36px; }
    .datatable .react-bootstrap-table .table.table-condensed-medium tbody td {
      padding: 8px;
      height: 36px; }
  .datatable .datatable-footer .datatable-footer-inner .page-count {
    font-weight: 300;
    display: none; }
  .datatable .datatable-footer .datatable-footer-inner .datatable-pager .pager {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .datatable .datatable-footer .datatable-footer-inner .datatable-pager .pager li {
      display: flex;
      text-align: center;
      flex-wrap: nowrap;
      width: 32px;
      height: 32px;
      border-radius: 0.1rem;
      align-items: center; }
      .datatable .datatable-footer .datatable-footer-inner .datatable-pager .pager li a {
        width: 100%;
        line-height: 1; }
        .datatable .datatable-footer .datatable-footer-inner .datatable-pager .pager li a:hover {
          text-decoration: none; }
        .datatable .datatable-footer .datatable-footer-inner .datatable-pager .pager li a i {
          vertical-align: middle; }
      .datatable .datatable-footer .datatable-footer-inner .datatable-pager .pager li.active {
        background: #3CB5AD; }
        .datatable .datatable-footer .datatable-footer-inner .datatable-pager .pager li.active a {
          color: white; }
      .datatable .datatable-footer .datatable-footer-inner .datatable-pager .pager li:hover {
        background: white;
        border: 2px solid #3CB5AD; }
        .datatable .datatable-footer .datatable-footer-inner .datatable-pager .pager li:hover a {
          color: #3CB5AD; }
      .datatable .datatable-footer .datatable-footer-inner .datatable-pager .pager li.disabled {
        border: none; }
        .datatable .datatable-footer .datatable-footer-inner .datatable-pager .pager li.disabled a {
          color: #ccc;
          cursor: not-allowed; }

.icon {
  overflow: visible;
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px; }
  .icon::before {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    height: 24px;
    width: 24px; }
  .icon.icon_arrow_dark {
    transform: rotate(90deg);
    height: 16px;
    width: 16px; }
    .icon.icon_arrow_dark::before {
      background: url("../gfx/icons/arrows/arrow_down_dark.svg") no-repeat;
      background-position-x: 50%;
      background-position-y: 50%;
      background-size: 10px;
      height: 16px;
      width: 16px; }
  .icon.rotate-0 {
    transform: rotate(0); }
  .icon.icon_arrow_gray {
    transform: rotate(270deg);
    height: 16px;
    width: 16px; }
    .icon.icon_arrow_gray::before {
      background: url("../gfx/icons/arrows/arrow_down_gray.svg") no-repeat;
      background-position-x: 50%;
      background-position-y: 50%;
      background-size: 10px;
      height: 16px;
      width: 16px; }
  .icon.icon_send {
    margin-left: 5px;
    height: 12px;
    width: 12px; }
    .icon.icon_send::before {
      background: url("../gfx/icons/general/icon_send_primary.svg") no-repeat;
      background-position-x: 50%;
      background-position-y: 50%;
      background-size: 12px;
      transition: 0.15s;
      height: 12px;
      width: 12px; }
  .icon.icon_more-info::before {
    background: url("../gfx/icons/general/icon_more-info-24px.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 32px; }
  .icon.icon_more-info-primary::before {
    background: url("../gfx/icons/general/icon_more-info-24px-primary.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 28px; }
  .icon.icon_messages::before {
    background: url("../gfx/icons/general/icon_messages-24px.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 27px; }
  .icon.icon_arrow-white {
    height: 24px;
    width: 24px;
    margin-right: 5px; }
    .icon.icon_arrow-white::before {
      background: url("../gfx/icons/arrow_down-24px-white.svg") no-repeat;
      background-position-x: 50%;
      background-position-y: 100%;
      background-size: 24px;
      height: 24px;
      width: 24px; }
  .icon.icon_settings::before {
    background: url("../gfx/icons/general/icon_settings.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 24px; }
  .icon.icon_search {
    height: 20px;
    width: 20px; }
    .icon.icon_search::before {
      mask: url("../gfx/icons/general/icon_search2.svg") no-repeat;
      background-color: #00366A;
      background-position-x: 50%;
      background-position-y: 50%;
      background-size: 20px; }
  .icon.search-icon-inside-input {
    height: 16px;
    width: 16px;
    left: 0;
    background: transparent;
    margin-left: 6px; }
    .icon.search-icon-inside-input::before {
      mask: url("../gfx/icons/general/icon_search2.svg") no-repeat;
      background-color: #00366A;
      background-position-x: 50%;
      background-position-y: 50%;
      mask-size: 16px; }
  .icon.mask-gray::before {
    background-color: #ced4da; }
  .icon.icon_filter::before {
    background: url("../gfx/icons/general/icon_filter.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 24px; }
  .icon.icon_close::before {
    background: url("../gfx/icons/general/icon_close.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 24px; }
  .icon.icon_close-white::before {
    background: url("../gfx/icons/general/icon_close-white.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 24px; }
  .icon.icon-lg::before {
    background-size: 40px; }
  .icon.icon-small {
    height: 12px; }
    .icon.icon-small::before {
      height: 12px;
      background-size: 12px; }
  .icon.icon_upload::before {
    background: url("../gfx/icons/general/icon_upload.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 24px; }
  .icon.icon_exclamation-mark::before {
    background: url("../gfx/icons/general/icon_exclamation-mark-24px.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 24px; }
  .icon.technology-works::before {
    background: url("../gfx/icons/contract-types/technology-works.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 24px; }
  .icon.technology-works-selected::before {
    background: url("../gfx/icons/contract-types/technology-works-selected.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 24px; }
  .icon.maintenance-works::before {
    background: url("../gfx/icons/contract-types/maintenance-works.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 24px; }
  .icon.maintenance-works-selected::before {
    background: url("../gfx/icons/contract-types/maintenance-works-selected.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 24px; }
  .icon.security-service::before {
    background: url("../gfx/icons/contract-types/security-service.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 24px; }
  .icon.security-service-selected::before {
    background: url("../gfx/icons/contract-types/security-service-selected.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 24px; }
  .icon.inventory::before {
    background: url("../gfx/icons/lease-application-icons/inventory.svg") no-repeat center;
    background-size: 24px; }
  .icon.parking::before {
    background: url("../gfx/icons/lease-application-icons/parking.svg") no-repeat center;
    background-size: 24px; }
  .icon.file-word::before {
    background: url("../gfx/icons/lease-application-icons/file-word.svg") no-repeat center;
    background-size: 20px; }
  .icon.users::before {
    background: url("../gfx/icons/lease-application-icons/users.svg") no-repeat center;
    background-size: 24px; }
  .icon.pencil::before {
    background: url("../gfx/icons/general/pencil.svg") no-repeat center;
    background-size: 20px; }
  .icon.building::before {
    background: url("../gfx/icons/meters/building.svg") no-repeat center;
    background-size: 24px; }
  .icon.building-filled::before {
    background: url("../gfx/icons/general/building-filled.svg") no-repeat center;
    background-size: 24px; }
  .icon.download::before {
    mask: url("../gfx/icons/general/download.svg") no-repeat center;
    background-color: #00366A;
    mask-size: 24px; }
  .icon.trash::before {
    mask: url("../gfx/icons/general/trash.svg") no-repeat center;
    mask-size: 16px;
    background-color: #00366A; }
  .icon.info-comment::before {
    mask: url("../gfx/icons/general/info-comment.svg") no-repeat center;
    mask-size: 20px;
    background-color: #00366A; }
  .icon.external-link {
    width: 22px;
    height: 22px; }
    .icon.external-link::before {
      mask: url("../gfx/icons/general/external-link.svg") no-repeat center;
      mask-size: 18px;
      background-color: #3CB5AD; }
  .icon.exclamation::before {
    mask: url("../gfx/icons/meters/exclamation.svg") no-repeat center;
    mask-size: 100%;
    background-color: #F8A455; }
  .icon.check-circle::before {
    mask: url("../gfx/icons/meters/check-circle.svg") no-repeat center;
    mask-size: 100%;
    background-color: #68cc8c; }
  .icon.icon-lg {
    width: 32px;
    height: 32px; }
    .icon.icon-lg::before {
      height: 32px;
      width: 32px;
      background-size: 32px;
      mask-size: 30px; }
  .icon.icon-xl {
    width: 50px;
    height: 50px; }
    .icon.icon-xl::before {
      width: 50px;
      height: 50px;
      background-size: 50px;
      mask-size: 50px; }
  .icon.icon-sm {
    width: 16px;
    height: 16px; }
    .icon.icon-sm::before {
      height: 16px;
      width: 16px;
      background-size: 16px;
      mask-size: 16px; }
  .icon.icon-bg-danger::before {
    background-color: #FF0048; }
  .icon.icon-bg-success::before {
    background-color: #68cc8c; }
  .icon.icon-gray::before {
    background-color: #ced4da; }
  .icon.icon-white::before {
    background-color: #fff; }

.hover-icon-white {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .hover-icon-white:hover .icon:before {
    background-color: white; }

.hover-icon-secondary {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .hover-icon-secondary:hover .icon:before {
    background-color: #297c76; }

.btn-send {
  display: flex;
  align-items: center;
  justify-content: center; }
  .btn-send:hover .icon_send::before {
    background: url("../gfx/icons/general/icon_send_white.svg") no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 12px;
    transition: 0.15s; }

.rotate-90 {
  transform: rotate(90deg); }

.rotate-270 {
  transform: rotate(270deg) !important; }

.rotate-0 {
  transform: rotate(0deg) !important; }

p {
  font-size: 14px;
  color: #242835;
  margin-top: auto;
  margin-bottom: auto; }
  p.text-sm {
    font-size: 12px; }

h6 {
  margin-bottom: 0;
  line-height: 1.5; }

h4 {
  line-height: 1; }

.text-gray {
  color: #5E6478; }

.text-light-gray {
  color: #99afc3 !important; }

.text-sm {
  font-size: 12px; }

ul {
  list-style-type: none;
  margin-bottom: 0; }

.title-border-bottom {
  border-bottom: 1px solid #34394c17; }

.white-space-normal {
  white-space: normal; }

.font-size-16 {
  font-size: 1rem; }

.bg-hover:hover {
  background-color: #FAFAFA; }

.text-line-through {
  text-decoration: line-through;
  color: #ced4da; }

.text-disabled {
  color: #989a9c; }

@media (max-width: 1119.98px) {
  p {
    margin-top: auto; } }

@media (min-width: 1120px) and (max-width: 1249.98px) {
  .text-lg-small {
    font-size: 12px; } }

@media (max-width: 699.98px) {
  .text-lg-small {
    font-size: 11px; } }

.block-white {
  box-shadow: 0 0 14px 0 #E7E7E7;
  padding: 16px;
  background: white;
  width: 100%;
  min-height: 100px;
  margin-bottom: 24px; }

/*.title-padding-for-container {
  padding-left: 140px;
  padding-right: 140px;
}
  */
.work-messages {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding-right: 0; }
  @media (max-width: 1119.98px) {
    .work-messages {
      display: none; } }
  @media (min-width: 1120px) {
    .work-messages {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
      max-width: 600px !important; }
      .work-messages .message-row {
        width: 100%;
        margin-bottom: 25px; }
        .work-messages .message-row .message {
          max-width: 80%; }
      .work-messages .log-row {
        width: 100%;
        padding-bottom: 10px;
        padding-top: 10px;
        border-bottom: 1px solid rgba(204, 204, 204, 0.66); }
        .work-messages .log-row:last-child {
          border-bottom: none; } }

.expense-row {
  position: relative;
  background-color: #ced4da38;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .expense-row:hover {
    background: #34394c17;
    transition: .3s ease-in-out; }
  .expense-row.edit-mode {
    background: #34394c17;
    border: 2px solid #5E6478; }
  .expense-row .fixed-width-200px {
    width: 100%;
    min-width: 100%; }
  .expense-row .expense-edit-btn {
    position: relative;
    top: -8px;
    right: -8px; }
  @media (min-width: 700px) {
    .expense-row {
      flex-direction: row; }
      .expense-row .fixed-width-200px {
        width: 200px;
        min-width: 200px; }
      .expense-row.edit-mode {
        flex-direction: column; }
      .expense-row .expense-edit-btn {
        position: absolute;
        right: 0;
        top: 0; } }

.row-bg {
  background-color: #ced4da38; }

.table-row-border td {
  border-top: 1px solid  #ced4da38;
  border-bottom: 1px solid  #ced4da38; }
  .table-row-border td:first-child {
    border-left: 1px solid #ced4da38; }
  .table-row-border td:last-child {
    border-right: 1px solid #ced4da38; }

.edit-mode-2 {
  border: 2px solid #5E6478;
  transition: .3s ease-in-out; }

@media (max-width: 1119.98px) {
  .container {
    width: 100% !important;
    max-width: 100% !important; } }

/*@include media-breakpoint-down(sm) {
  .title-padding-for-container {
    padding-left: 0;
    padding-right: 0;
    }
  }
  */
.form-control-with-search-icon {
  position: relative;
  display: flex;
  align-items: center; }
  .form-control-with-search-icon input {
    border: white;
    padding-left: 40px;
    background-color: #FAFAFA; }
  .form-control-with-search-icon .btn {
    position: absolute;
    right: 16px;
    display: flex; }
  .form-control-with-search-icon .icon_search {
    position: absolute;
    left: 16px; }

/*.property-selection {
    border:2px solid $light-gray;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-radius: 2px;
    &:hover {
        background: #F6F8FA;
    }
    &.selected {
        border:2px solid $dark;
        background: $dark;
        color: white;
    }
}*/
.property-result {
  margin-bottom: 8px;
  padding: 4px;
  cursor: pointer;
  border-radius: 2px;
  padding-right: 12px; }
  .property-result:hover {
    background-color: #ced4da30; }
  .property-result.selected {
    background-color: #ced4da30; }

.project-manager-label {
  width: 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  margin-top: 8px;
  margin-bottom: 0; }

.tox-tinymce {
  border: 2px solid #ced4da !important;
  border-radius: 2px !important; }

.tox-tinymce *:focus {
  border: 2px solid #00366A !important; }

label {
  margin-bottom: 0; }

.custom-form-row {
  margin-top: 10px;
  margin-bottom: 10px;
  /*&:last-child {
        margin-bottom: 0;
    }*/ }
  .custom-form-row .col-12 {
    display: flex;
    align-items: center; }
    .custom-form-row .col-12 label {
      width: 150px;
      max-width: 150px;
      min-width: 150px;
      margin-bottom: 0; }
      .custom-form-row .col-12 label.custom-control-label {
        width: 100%;
        max-width: 100%;
        min-width: 100%; }
      .custom-form-row .col-12 label.label-sm {
        width: 100px;
        max-width: 100px;
        min-width: 100px; }
      .custom-form-row .col-12 label.label-lg {
        width: 200px;
        max-width: 200px;
        min-width: 200px; }
  .custom-form-row .col-6 {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .custom-form-row .col-6 label {
      width: 150px;
      max-width: 150px;
      min-width: 150px;
      margin-bottom: 0; }
  .custom-form-row small {
    margin-left: 155px; }
  .custom-form-row:first-child {
    margin-top: 0; }

.table-custom-row td.editable {
  padding-top: 0px;
  padding-bottom: 0px;
  transition: 0.15s padding-top padding-bottom ease; }

.table-custom-row.edit-table-custom-row {
  box-shadow: 0 0 16px #ccc;
  display: table-row;
  position: relative; }
  .table-custom-row.edit-table-custom-row td {
    display: table-cell; }
    .table-custom-row.edit-table-custom-row td.editable {
      padding-top: 10px;
      padding-bottom: 10px;
      transition: 0.15s padding-top padding-bottom ease; }
  .table-custom-row.edit-table-custom-row:hover {
    background: none !important; }

.edit-custom-form-row {
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 0 16px #ccc;
  transition: 0.15s padding-top padding-bottom ease; }

.edit-building {
  padding: 10px;
  box-shadow: 0 0 16px #ccc; }

.custom-control-input:active {
  background-color: #00366A !important; }

.custom-control {
  padding-left: 2rem; }
  .custom-control.form-control {
    border-color: transparent;
    line-height: inherit;
    padding-top: 0; }

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #00366A;
  border-color: #00366A; }

.custom-checkbox .custom-control-label {
  cursor: pointer; }
  .custom-checkbox .custom-control-label::before {
    border-radius: 1px; }

.search-input-table {
  height: 39px;
  position: absolute;
  bottom: 0;
  background: white;
  left: 0;
  width: 100%;
  border-top: 1px solid #E4E4E4;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .search-input-table input {
    border: none;
    height: 30px;
    border: none;
    width: 100%;
    position: absolute;
    padding-left: 30px; }
  .search-input-table.field-disabled {
    background: #F6F8FA; }
  .search-input-table .custom-select.in-table {
    border: none; }
  .search-input-table .custom-select:focus {
    border: none;
    box-shadow: none; }

.search-input {
  height: 44px;
  padding-left: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .search-input input {
    height: 40px;
    border: none;
    width: calc(100% - 55px);
    position: absolute;
    padding-left: 30px; }

@media (max-width: 1119.98px) {
  /* //DUBLICATE, TODO:REMOVE
    .w-200 {
        width: 150px;
        min-width: 150px;
        max-width: 150px;
    }*/
  .custom-form-row {
    margin-top: 16px;
    margin-bottom: 16px; }
    .custom-form-row .col-12 {
      display: flex;
      align-items: flex-start;
      flex-direction: column; }
    .custom-form-row small {
      margin-left: 5px; }
    .custom-form-row .col-6 {
      display: flex;
      align-items: flex-start;
      flex-direction: column; } }

.borderless-input {
  border: none !important; }

.col-input {
  width: 60px; }

.form-control-table-inline {
  border: none !important;
  padding: 0;
  padding-left: 5px;
  border-radius: 0;
  background: #55518726;
  border-radius: 2px; }
  .form-control-table-inline.empty {
    background: #ff004836; }
  .form-control-table-inline.filled {
    background: white; }
  .form-control-table-inline.disabled {
    background: #eeeeee;
    color: #989a9c; }

.table-inline-dropdown {
  width: 100%; }
  .table-inline-dropdown button {
    text-align: left;
    padding-left: 5px;
    padding-right: 0;
    background: #55518726; }
    .table-inline-dropdown button.filled {
      background: white; }
  .table-inline-dropdown.show .caret.caret-primary::before {
    background-color: #00366A; }
  .table-inline-dropdown.show .selected a {
    font-weight: bold !important; }
  .table-inline-dropdown.disabled button {
    background: #eeeeee;
    color: #989a9c;
    cursor: auto; }
    .table-inline-dropdown.disabled button .caret {
      display: none; }

.navbar {
  background: white;
  height: 60px;
  padding: 0;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  z-index: 90;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.22);
  display: flex;
  align-items: center;
  z-index: 1030; }
  .navbar.navbar-general-page {
    justify-content: center;
    z-index: 1029 !important;
    background: white; }
    .navbar.navbar-general-page > ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      padding-left: 0; }
      .navbar.navbar-general-page > ul .nav-item {
        height: 60px;
        display: flex;
        align-items: center; }
        .navbar.navbar-general-page > ul .nav-item .nav-link {
          padding-left: 30px;
          padding-right: 30px;
          cursor: pointer;
          border-right: 1px solid #ced4da;
          padding-top: 0;
          padding-bottom: 0;
          font-size: 14px;
          letter-spacing: 0.7px;
          line-height: 18px;
          color: #6d6d6d;
          letter-spacing: 0; }
          @media (min-width: 1120px) and (max-width: 1249.98px) {
            .navbar.navbar-general-page > ul .nav-item .nav-link {
              padding-left: 20px;
              padding-right: 20px; } }
        .navbar.navbar-general-page > ul .nav-item:last-child .nav-link {
          border-right: none; }
        .navbar.navbar-general-page > ul .nav-item.active {
          border-bottom: 3px solid #00366A; }
          .navbar.navbar-general-page > ul .nav-item.active .nav-link {
            color: #00366A; }
  .navbar.navbar-details-page {
    justify-content: space-between;
    z-index: 1029 !important;
    background: white; }
    .navbar.navbar-details-page > ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      padding-left: 0; }
      .navbar.navbar-details-page > ul .nav-item {
        display: flex;
        align-items: center;
        justify-content: center; }
        .navbar.navbar-details-page > ul .nav-item .nav-link-back {
          color: #34394C;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #ced4da;
          padding-right: 24px;
          cursor: pointer; }
          .navbar.navbar-details-page > ul .nav-item .nav-link-back .icon {
            margin-right: -8px; }
          .navbar.navbar-details-page > ul .nav-item .nav-link-back .nav-link-back-label {
            padding-left: 12px;
            line-height: 1; }
          .navbar.navbar-details-page > ul .nav-item .nav-link-back:hover {
            text-decoration: none; }
      .navbar.navbar-details-page > ul .nav-dropdown {
        display: none; }
      .navbar.navbar-details-page > ul .nav-breadcrumb {
        display: flex;
        align-items: center; }
        .navbar.navbar-details-page > ul .nav-breadcrumb .btn {
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .navbar.navbar-details-page > ul .nav-breadcrumb .nav-link-current {
          padding: 8px 20px;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .navbar.navbar-details-page > ul .nav-breadcrumb .icon_arrow_gray {
          margin-left: -5px;
          margin-right: -5px; }
      .navbar.navbar-details-page > ul.navbar-action-buttons {
        display: flex; }
        .navbar.navbar-details-page > ul.navbar-action-buttons li {
          margin-left: 1rem; }

@media (max-width: 1119.98px) {
  .navbar.navbar-collapsible > ul .nav-item .nav-link {
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 8px;
    padding-right: 8px;
    transition: max-width 0.5s ease-out; }
    .navbar.navbar-collapsible > ul .nav-item .nav-link.active {
      max-width: initial; } }

@media (min-width: 700px) and (max-width: 1119.98px) {
  .navbar.navbar-general-page {
    display: flex; }
  .navbar.navbar-details-page {
    z-index: 100; }
    .navbar.navbar-details-page > ul .nav-item .nav-link-back {
      margin-right: 14px; }
      .navbar.navbar-details-page > ul .nav-item .nav-link-back .nav-link-back-label {
        display: none; }
    .navbar.navbar-details-page > ul .nav-breadcrumb .btn {
      padding: 4px 10px !important;
      max-width: 100px; }
    .navbar.navbar-details-page > ul .nav-breadcrumb .nav-link-current {
      padding: 4px 10px;
      max-width: 100px; }
    .navbar.navbar-details-page > ul .nav-breadcrumb .icon_arrow_gray {
      margin-left: 0;
      margin-right: 0; } }

@media (max-width: 699.98px) {
  .navbar {
    height: 50px; }
    .navbar.navbar-general-page > ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      padding-left: 0; }
      .navbar.navbar-general-page > ul .nav-item {
        display: none;
        height: 50px; }
        .navbar.navbar-general-page > ul .nav-item.active {
          display: flex;
          border-bottom: 3px solid #00366A; }
          .navbar.navbar-general-page > ul .nav-item.active .nav-link {
            color: #00366A;
            transition: 0.2s;
            border-right: 0; }
      .navbar.navbar-general-page > ul .nav-dropdown {
        position: absolute;
        top: 17px;
        right: 24px; }
    .navbar.navbar-details-page > ul .nav-item .nav-link-back {
      margin-right: 14px; }
      .navbar.navbar-details-page > ul .nav-item .nav-link-back .nav-link-back-label {
        display: none; }
    .navbar.navbar-details-page > ul .nav-breadcrumb .btn {
      padding: 4px 10px !important;
      max-width: 90px; }
    .navbar.navbar-details-page > ul .nav-breadcrumb .nav-link-current {
      padding: 4px 10px;
      max-width: 90px; }
    .navbar.navbar-details-page > ul .nav-breadcrumb .icon_arrow_gray {
      margin-left: 0;
      margin-right: 0; }
    .navbar.navbar-details-page > ul.navbar-action-buttons {
      display: none; } }

.table {
  width: 100%; }
  .table.table-service-contract-details {
    min-width: 1300px; }
    .table.table-service-contract-details tr th {
      vertical-align: middle;
      padding: 0.3rem; }
    .table.table-service-contract-details tr td {
      padding: 0.3rem;
      border-top: 1px solid #34394c17; }
      .table.table-service-contract-details tr td.col-disabled {
        background: #d2cdcd42; }
    .table.table-service-contract-details tr.row-striped {
      background: white; }
      .table.table-service-contract-details tr.row-striped:hover {
        background: #55518726; }
      .table.table-service-contract-details tr.row-striped .col-input .form-control-table-inline {
        background: #55518726; }
        .table.table-service-contract-details tr.row-striped .col-input .form-control-table-inline.filled {
          background: white !important; }
          .table.table-service-contract-details tr.row-striped .col-input .form-control-table-inline.filled:focus {
            background: white !important; }
        .table.table-service-contract-details tr.row-striped .col-input .form-control-table-inline:focus {
          background: white; }
      .table.table-service-contract-details tr.row-striped .table__open-button {
        padding: 0;
        color: #34394C;
        display: block;
        text-align: left;
        font-size: 13px; }
    .table.table-service-contract-details .table-header td {
      min-height: 58px;
      height: 58px; }
  .table.table-lease-contract-details {
    min-width: 1300px; }
    .table.table-lease-contract-details tr th {
      vertical-align: middle;
      padding: 0.3rem; }
    .table.table-lease-contract-details tr td {
      padding: 0.3rem;
      border-top: 1px solid #34394c17; }
      .table.table-lease-contract-details tr td.col-disabled {
        background: #d2cdcd42; }
  .table tr.building-row.selected td {
    background: #fafafa; }
  .table tr.row-gray {
    background: rgba(246, 248, 250, 0.534); }
    .table tr.row-gray td {
      color: #ccc; }
  .table tr.row-moved {
    opacity: 0.5;
    box-shadow: 4px 0 0 0 wheat inset; }
  .table tr.row-normal {
    box-shadow: 4px 0 0 0 transparent inset; }
  .table tr.row-added {
    box-shadow: 4px 0 0 0 #97ca81 inset; }
  .table tr.row-deleted {
    opacity: 0.5;
    box-shadow: 4px 0 0 0 #c7c7c7 inset; }
  .table tr.row-just-deleted {
    opacity: 0.5;
    box-shadow: 4px 0 0 0 #f1a6a6 inset; }
  .table tr.row-just-changed {
    box-shadow: 4px 0 0 0 #8eaacc inset; }
  .table tr.row-invalid {
    border: 2px solid #f8a455; }
  .table tr td {
    vertical-align: middle; }
    .table tr td.checkbox-col {
      max-width: 60px;
      text-align: center;
      width: 50px;
      min-width: 50px; }
  .table.table-clickable tr {
    cursor: pointer; }
    .table.table-clickable tr:hover {
      background: #00000008; }
    .table.table-clickable tr.row-selected {
      background: #F6F8FA; }

.table-with-rows-spacing {
  border-collapse: separate;
  border-spacing: 0 10px; }

.datatable-style {
  display: table;
  width: 100%; }
  .datatable-style tr th {
    padding: 0 10px;
    height: 40px;
    border-bottom: 1px solid #E4E4E4;
    border-left: 1px solid #E4E4E4;
    border-top: 1px solid #E4E4E4;
    border-right: 1px solid #E4E4E4;
    background-color: #F6F8FA;
    color: #868A97;
    font-weight: 400;
    font-size: 13px; }
  .datatable-style tr td {
    padding: 0 4px;
    border-bottom: 1px solid #E4E4E4;
    border-left: 1px solid #E4E4E4;
    border-top: 1px solid #E4E4E4;
    border-right: 1px solid #E4E4E4;
    font-weight: 400;
    font-size: 13px;
    height: 36px; }
  .datatable-style .summary-row td {
    height: 40px;
    background-color: #F6F8FA;
    font-weight: bold; }

.w-250 {
  min-width: 250px; }

.table-w-50 {
  min-width: 50px;
  width: 50px;
  max-width: 50px; }

.table-w-80 {
  min-width: 80px;
  width: 80px;
  max-width: 80px; }

.table-w-100 {
  min-width: 100px;
  width: 100px;
  max-width: 100px; }

.table-w-110 {
  min-width: 110px;
  width: 110px;
  max-width: 110px; }

.table-min-150 {
  min-width: 150px; }

table.table-collapsible thead tr th.column1 {
  max-width: 500px;
  width: 500px; }

table.table-collapsible tbody tr td.column1 {
  cursor: pointer;
  max-width: 500px;
  width: 500px; }

table.table-collapsible tbody tr td.column.disabled {
  background: #efefef2b;
  text-decoration: line-through; }

table.table-collapsible tbody tr td.column .form-control {
  height: 30px;
  padding: 5px; }

.clickable {
  cursor: pointer; }

.table-separator-row {
  background: #F6F8FA; }
  .table-separator-row td {
    height: 10px !important; }
  .table-separator-row:hover {
    background: #F6F8FA !important; }

.table-hover tbody tr:hover {
  background: #F6F8FA; }

@media (min-width: 700px) and (max-width: 1119.98px) {
  .datatable-style {
    overflow-x: auto; } }

@media (max-width: 699.98px) {
  .datatable-style {
    overflow-x: auto;
    max-width: 750px;
    display: block; } }

.work-details {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding-left: 0; }
  @media (max-width: 549.98px) {
    .work-details {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 550px) and (max-width: 699.98px) {
    .work-details {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 700px) and (max-width: 1119.98px) {
    .work-details {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 1120px) and (max-width: 1249.98px) {
    .work-details {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
      max-width: 900px !important; } }
  @media (min-width: 1250px) {
    .work-details {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
      max-width: 900px !important; } }

.progress-bar-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-content: center;
  margin-left: 0;
  margin-right: 0; }
  .progress-bar-row .progress-bar-col {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
    @media (min-width: 1120px) {
      .progress-bar-row .progress-bar-col {
        flex: 0 0 100%;
        max-width: 100%;
        max-width: 1500px;
        padding-left: 0;
        padding-right: 0; } }
    @media (max-width: 1119.98px) {
      .progress-bar-row .progress-bar-col {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0; } }

.work-details-full-width {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }
  @media (max-width: 1249.98px) {
    .work-details-full-width {
      flex: 0 0 100%;
      max-width: 100%; } }
  .work-details-full-width .work-details-1-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 8px; }
    .work-details-full-width .work-details-1-row .work-details-1-col {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      padding-left: 1rem;
      padding-right: 1rem; }
      @media (max-width: 699.98px) {
        .work-details-full-width .work-details-1-row .work-details-1-col {
          display: block;
          flex: 0 0 100%;
          max-width: 100%; }
          .work-details-full-width .work-details-1-row .work-details-1-col .work-details-1-label {
            min-width: 120px;
            margin-top: auto; }
          .work-details-full-width .work-details-1-row .work-details-1-col .work-details-1-value {
            font-weight: bold; } }
      @media (min-width: 700px) and (max-width: 1119.98px) {
        .work-details-full-width .work-details-1-row .work-details-1-col {
          display: flex;
          flex: 0 0 100%;
          max-width: 100%; }
          .work-details-full-width .work-details-1-row .work-details-1-col .work-details-1-label {
            min-width: 120px;
            margin-top: auto; }
          .work-details-full-width .work-details-1-row .work-details-1-col .work-details-1-value {
            font-weight: bold; } }
      @media (min-width: 1120px) {
        .work-details-full-width .work-details-1-row .work-details-1-col {
          display: flex;
          flex: 0 0 100%;
          max-width: 100%; }
          .work-details-full-width .work-details-1-row .work-details-1-col .work-details-1-label {
            min-width: 120px; }
          .work-details-full-width .work-details-1-row .work-details-1-col .work-details-1-value {
            font-weight: bold; } }
    .work-details-full-width .work-details-1-row .work-details-1-col-6 {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px; }
      @media (max-width: 699.98px) {
        .work-details-full-width .work-details-1-row .work-details-1-col-6 {
          display: block;
          flex: 0 0 100%;
          max-width: 100%; }
          .work-details-full-width .work-details-1-row .work-details-1-col-6 .work-details-1-label {
            min-width: 120px; }
          .work-details-full-width .work-details-1-row .work-details-1-col-6 .work-details-1-value {
            font-weight: bold; } }
      @media (min-width: 700px) and (max-width: 1119.98px) {
        .work-details-full-width .work-details-1-row .work-details-1-col-6 {
          display: flex;
          flex: 0 0 50%;
          max-width: 50%; }
          .work-details-full-width .work-details-1-row .work-details-1-col-6 .work-details-1-label {
            min-width: 120px; }
          .work-details-full-width .work-details-1-row .work-details-1-col-6 .work-details-1-value {
            font-weight: bold; } }
      @media (min-width: 1120px) {
        .work-details-full-width .work-details-1-row .work-details-1-col-6 {
          display: flex;
          flex: 0 0 50%;
          max-width: 50%; }
          .work-details-full-width .work-details-1-row .work-details-1-col-6 .work-details-1-label {
            min-width: 120px; }
          .work-details-full-width .work-details-1-row .work-details-1-col-6 .work-details-1-value {
            font-weight: bold; } }

.work-details-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }
  @media (max-width: 699.98px) {
    .work-details-1 {
      flex: 0 0 100%;
      max-width: 100%; } }
  @media (min-width: 700px) and (max-width: 1119.98px) {
    .work-details-1 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; } }
  @media (min-width: 1120px) {
    .work-details-1 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; } }
  .work-details-1 .work-details-1-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 8px; }
    .work-details-1 .work-details-1-row .work-details-1-col {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px; }
      @media (max-width: 699.98px) {
        .work-details-1 .work-details-1-row .work-details-1-col {
          display: block;
          flex: 0 0 100%;
          max-width: 100%; }
          .work-details-1 .work-details-1-row .work-details-1-col .work-details-1-label {
            min-width: 120px; }
          .work-details-1 .work-details-1-row .work-details-1-col .work-details-1-value {
            font-weight: bold; } }
      @media (min-width: 700px) and (max-width: 1119.98px) {
        .work-details-1 .work-details-1-row .work-details-1-col {
          display: flex;
          flex: 0 0 100%;
          max-width: 100%; }
          .work-details-1 .work-details-1-row .work-details-1-col .work-details-1-label {
            min-width: 120px; }
          .work-details-1 .work-details-1-row .work-details-1-col .work-details-1-value {
            font-weight: bold; } }
      @media (min-width: 1120px) {
        .work-details-1 .work-details-1-row .work-details-1-col {
          display: flex;
          flex: 0 0 100%;
          max-width: 100%; }
          .work-details-1 .work-details-1-row .work-details-1-col .work-details-1-label {
            min-width: 120px; }
          .work-details-1 .work-details-1-row .work-details-1-col .work-details-1-value {
            font-weight: bold; } }

.modal-header {
  display: flex;
  align-items: center;
  padding: 0.7rem 1rem;
  height: 60px; }
  .modal-header .submenu-hover {
    height: 40px;
    width: 40px;
    position: absolute;
    background: #cccccc00;
    border-radius: 50%;
    transform: scale(0);
    transition: all 0.2s ease-in-out;
    left: 15%;
    top: 14%; }

.submenu-hover {
  height: 40px;
  width: 40px;
  position: absolute;
  background: #cccccc00;
  border-radius: 50%;
  transform: scale(0);
  transition: all 0.2s ease-in-out; }
  .submenu-hover.submenu-hover-position {
    left: -35%;
    top: -35%; }

.close {
  opacity: 1;
  position: relative;
  text-shadow: none;
  display: flex;
  line-height: 0.5;
  padding: 0.8rem 1.4rem; }
  .close:not(:disabled):not(.disabled):hover {
    opacity: 1; }
  .close:not(:disabled):not(.disabled):focus {
    opacity: 1; }
  .close i {
    color: #00366A; }
  .close:hover .submenu-hover {
    background-color: #cccccc4e;
    transition: all 0.2s ease-in-out;
    transform: scale(1); }
  .close:focus {
    opacity: 1;
    outline: none; }
    .close:focus .submenu-hover {
      background-color: #cccccc84;
      transition: all 0.2s ease-in-out;
      transform: scale(1); }

.modal-dialog {
  max-width: 600px; }
  .modal-dialog.modal-sm {
    max-width: 400px; }
  .modal-dialog.modal-md {
    max-width: 800px; }

.modal-content {
  border-radius: 2px;
  border: none; }

.modal-xl {
  max-width: 1100px; }
  .modal-xl .modal-body {
    background: #FAFAFA; }

.double-modal {
  margin: 0; }
  .double-modal.modal-dialog {
    max-width: 100%; }
    .double-modal.modal-dialog .modal-content {
      height: 100vh;
      background: #00000051; }
      .double-modal.modal-dialog .modal-content .modal-container-white {
        background: white;
        max-width: 1100px;
        margin: auto;
        margin-top: 100px; }
  .double-modal .modal-body {
    background: #FAFAFA; }

.modal-footer {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem; }
  .modal-footer.footer-padding {
    padding-left: 30px;
    padding-right: 30px; }

@media (max-width: 699.98px) {
  .modal-xl {
    max-width: 100%;
    margin: 0; }
    .modal-xl .modal-content {
      border-radius: 0; }
      .modal-xl .modal-content .modal-body {
        padding: 0; }
  .modal-dialog.modal-md {
    max-width: 100%; } }

@media (max-width: 1119.98px) {
  .modal-header {
    height: 50px; }
  .modal-xl {
    max-width: 100%; } }

@media (min-width: 1120px) and (max-width: 1249.98px) {
  .modal-xl {
    max-width: 95%; } }

@media (min-width: 1250px) and (max-width: 1399.98px) {
  .modal-xl {
    max-width: 90%; } }

@media (min-width: 1400px) {
  .modal-xl {
    max-width: 1300px; } }

.popover-xl {
  max-width: 500px; }

.switch {
  display: inline-block;
  position: relative;
  font-size: 13px;
  line-height: 24px; }
  .switch-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 20px;
    opacity: 0;
    z-index: 0; }
  .switch-label {
    display: block;
    padding: 0 0 0 44px;
    cursor: pointer; }
    .switch-label:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      width: 36px;
      height: 14px;
      background-color: rgba(0, 0, 0, 0.26);
      border-radius: 14px;
      z-index: 1;
      transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1); }
    .switch-label:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 14px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      z-index: 2;
      transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
      transition-property: left, background-color; }
  .switch-input:checked + .switch-label:before {
    background-color: rgba(0, 0, 0, 0.26); }
  .switch-input:checked + .switch-label:after {
    left: 16px;
    background-color: #00366A; }

.filter nav {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 420px;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(201, 209, 222, 0);
  z-index: 2001;
  transition: 0.3s;
  transform: translateX(100%);
  padding: 20px 20px 0 20px;
  overflow-x: visible;
  overflow-y: auto;
  -webkit-overflow-scrolling: auto; }
  .filter nav.width-500 {
    width: 500px; }
    .filter nav.width-500 .filter-header-fixed {
      position: sticky;
      width: 480px;
      margin-left: -20px;
      padding-left: 20px;
      margin-top: -20px; }
  .filter nav .toggle {
    cursor: pointer;
    position: absolute;
    right: -35px;
    top: 15px;
    color: white;
    display: none; }
  .filter nav .dateFilter-btn-group label {
    width: 33.3%; }
  .filter nav .act-status-btn-group {
    display: flex; }
    .filter nav .act-status-btn-group label {
      width: 25%; }
  .filter nav .filter-buttons {
    display: flex;
    align-items: center;
    position: sticky;
    width: 100%;
    padding-bottom: 20px;
    padding-top: 20px;
    bottom: 0;
    background: white;
    justify-content: space-between;
    z-index: 200; }
    .filter nav .filter-buttons .btn {
      width: 45%; }

.filter.show nav {
  transform: translateX(0);
  transition: 0.3s; }

.filter.show > .background {
  display: inline; }

.filter.add-offer-sidenav nav {
  width: 800px;
  overflow-y: auto; }

.filter-header-fixed {
  position: sticky;
  width: 365px;
  padding-bottom: 20px;
  top: -20px;
  background: white;
  z-index: 200;
  padding-top: 20px; }

.dateFilter {
  visibility: hidden;
  transform: translateY(-100%);
  transition: all 0.2s;
  opacity: 0;
  z-index: -1; }
  .dateFilter.visible {
    visibility: visible;
    transform: translateY(0);
    transition: all 0.2s;
    opacity: 1; }

.transform {
  transition: all 0.2s;
  transform: translateY(-40px); }
  .transform.down {
    transform: translateY(0);
    transition: all 0.2s;
    position: relative;
    z-index: 200; }

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem; }

.custom-day.focused {
  background-color: #e6e6e6; }

.custom-day.range, .custom-day:hover {
  background-color: #0275d8;
  color: white; }

.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5); }

.filters-background {
  opacity: 0;
  transition: opacity 0.2s linear; }

.filters-open {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  padding-right: 15px; }
  .filters-open .filters-background {
    background: #000000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    opacity: 0.5;
    transition: opacity 0.2s linear; }

.lease-offer-building-container {
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom: 1px solid #ededef; }
  .lease-offer-building-container .building-name {
    display: flex;
    flex-direction: column; }
    .lease-offer-building-container .building-name span {
      color: #99afc3;
      font-size: 12px;
      line-height: 1; }
    .lease-offer-building-container .building-name h4 {
      color: #34394C;
      line-height: 1;
      font-weight: bold; }

@media (max-width: 699.98px) {
  .filter nav {
    width: 100%;
    padding: 20px 10px;
    padding-bottom: 0;
    height: 100%; }
    .filter nav .filter-buttons {
      width: 100%;
      padding-bottom: 10px;
      padding-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .filter nav .filter-buttons .btn {
        width: 45%; }
    .filter nav.width-500 {
      width: 100vw; }
      .filter nav.width-500 .filter-header-fixed {
        position: sticky;
        width: 100vw;
        margin-left: 0;
        padding-left: 20px;
        margin-top: 0; }
  .filter.add-offer-sidenav nav {
    width: 100vw;
    overflow-y: auto; } }

.contracts-history-row {
  position: relative;
  background-color: #ced4da3d;
  margin-top: 10px;
  padding: 8px;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap; }
  .contracts-history-row .status-circle {
    width: 14px;
    height: 14px;
    position: absolute;
    border-radius: 50%;
    top: 30%;
    left: -30px; }

.building-contract {
  border: 2px solid #ced4da;
  padding: 6px;
  margin-bottom: 12px;
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 2px; }
  .building-contract:hover {
    border: 2px solid #dedede; }
  .building-contract.selected {
    border: 2px solid #3CB5AD; }

.building-contract-sm {
  border: 2px solid #ced4da;
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  margin-left: 0.5rem;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  min-width: 100px; }
  .building-contract-sm h6 {
    font-size: 0.8rem;
    color: #5E6478; }
  .building-contract-sm:hover {
    border: 2px solid #5E6478; }
    .building-contract-sm:hover h6 {
      color: #00366A; }
  .building-contract-sm.selected {
    background-color: #3CB5AD;
    border: 2px solid #3CB5AD; }
    .building-contract-sm.selected h6 {
      color: white; }
  .building-contract-sm:first-child {
    margin-left: 0; }

.contract-file-grid {
  display: grid;
  grid-template-columns: 105px auto 105px;
  grid-template-rows: auto; }
  @media (max-width: 699.98px) {
    .contract-file-grid {
      grid-template-columns: auto;
      grid-template-rows: 36px 1fr 40px; }
      .contract-file-grid .badge {
        width: 100%; } }

footer {
  flex-shrink: 0;
  display: flex;
  height: 60px;
  padding-left: 24px;
  padding-right: 24px;
  border-top: 1px solid rgba(204, 204, 204, 0.386); }
  footer .footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }

@media (max-width: 699.98px) {
  height: 50px;
  padding-left: 12px;
  padding-right: 12px; }

.react-select {
  border: 0;
  min-height: 0;
  width: 100%; }
  .react-select .react-select__control {
    background-color: #ffffff;
    border-radius: 2px;
    border: 2px solid #ced4da;
    min-height: 40px;
    align-items: center;
    box-shadow: 0 0 0 0; }
    .react-select .react-select__control.react-select__control--is-focused {
      border-color: #34394C; }
      .react-select .react-select__control.react-select__control--is-focused:not(.react-select__control--menu-is-open) {
        border-color: #34394C;
        box-shadow: 0 0 0 0; }
    .react-select .react-select__control.react-select__control--is-disabled {
      background-color: #f9f9f9; }
    .react-select .react-select__control.react-select__control--menu-is-open .react-select__indicator {
      transform: rotate(180deg); }
    .react-select .react-select__control:hover:not(.react-select__control--is-focused) {
      border-color: #ced4da; }
    .react-select .react-select__control .react-select__indicator {
      transition: 0.2s ease-in-out; }

.select-addrows-modal {
  width: 160px !important; }

.react-select__menu {
  background-color: #ffffff;
  border: 2px solid #34394C;
  border-radius: 2px;
  box-shadow: 0 2px 6px 0 rgba(68, 75, 100, 0.21);
  left: 0; }
  .react-select__menu .react-select__menu-list {
    padding: 0; }
    .react-select__menu .react-select__menu-list .react-select__option {
      background-color: #ffffff;
      color: rgba(0, 0, 0, 0.87);
      padding: 8px 10px;
      white-space: normal !important; }
      .react-select__menu .react-select__menu-list .react-select__option.react-select__option--is-focused {
        background-color: #ededed;
        color: #333; }
      .react-select__menu .react-select__menu-list .react-select__option.react-select__option--is-selected {
        color: white;
        background-color: #34394C;
        font-weight: 600; }

.block {
  padding: 0;
  width: 100%;
  min-height: 100px;
  margin-bottom: 24px; }
  .block.block-white {
    background: white;
    box-shadow: 0 0 14px 0 #E7E7E7; }
  .block.width-100 {
    min-width: 100%; }
  .block.block-collapsible {
    min-height: 43px; }
  .block.block-not-clickable {
    min-height: 43px;
    background: #ffffff;
    border: 1px solid #eaeaea; }
  .block .block-title {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    width: 100%;
    margin-bottom: 0; }
    .block .block-title.block-title-btn {
      color: #34394C;
      text-decoration: none;
      display: flex;
      align-items: center;
      text-align: left; }
      .block .block-title.block-title-btn.arrow-up span {
        transform: rotate(180deg); }
      .block .block-title.block-title-btn.arrow-down span {
        transform: rotate(0); }
  .block .complex-block-title {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    margin-bottom: 0;
    position: relative;
    cursor: auto; }
    .block .complex-block-title.block-title-btn {
      color: #34394C;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center; }
    .block .complex-block-title.tab-open {
      border-bottom: 3px solid #00366A; }
    .block .complex-block-title.block-active {
      cursor: pointer; }
  .block .complex-block-title-separator {
    border-right: 1px solid #ced4da;
    height: 21px; }
  .block .block-btn {
    white-space: nowrap;
    margin-right: 4px; }
  .block.block-padding {
    padding-left: 12px;
    padding-right: 12px; }
  .block .block-collapsible-content {
    max-height: 0;
    overflow-x: auto;
    overflow-y: hidden;
    transition: 0.3s linear; }
    .block .block-collapsible-content.open {
      max-height: 100%;
      visibility: visible;
      transition: 0.3s linear; }
  .block.block-height {
    height: calc(100vh - 229px);
    margin-bottom: 10px; }
  .block.block-with-double-header-height {
    height: calc(100vh - 189px);
    margin-bottom: 10px; }

@media (min-width: 550px) {
  .block {
    width: 100%; } }

@media (min-width: 700px) {
  .block {
    width: 100%; } }

@media (min-width: 1120px) {
  .block {
    max-width: 1100px; } }

.custom-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: -1px; }
  .custom-tabs .custom-tabs-link {
    cursor: pointer;
    border-bottom: 3px solid transparent;
    display: flex;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    align-items: center;
    font-size: 14px;
    justify-content: center;
    text-align: center; }
    .custom-tabs .custom-tabs-link.active {
      border-bottom: 3px solid #00366A; }
  .custom-tabs .link-separator {
    border-right: 1px solid rgba(204, 204, 204, 0.575);
    height: 20px; }

@media (min-width: 700px) and (max-width: 1119.98px) {
  .custom-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .custom-tabs .custom-tabs-link {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 14px;
      padding-top: 14px;
      font-size: 14px;
      width: 100%;
      min-height: 63px; } }

@media (max-width: 699.98px) {
  .custom-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .custom-tabs .custom-tabs-link {
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 14px;
      padding-top: 14px;
      font-size: 12px;
      min-height: 58px;
      width: 100%; } }

.quick-subfilter-bg {
  background: white;
  box-shadow: 0 0 14px 0 #b3b3b3c2;
  width: 200px;
  min-height: 100px;
  position: absolute;
  left: 130px;
  top: 0;
  border-radius: 2px;
  padding: 0.5rem;
  z-index: 2000; }

.works-link {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-right: 1rem;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 0;
  width: 125px;
  height: 50px;
  cursor: pointer;
  border-radius: 2px; }
  .works-link.filter-loader {
    height: 50px; }
    .works-link.filter-loader .quick-filter-label {
      visibility: hidden; }
    .works-link.filter-loader .works-filtered-number {
      visibility: hidden; }
  .works-link .quick-filter-label {
    animation: slide 0.15s ease-out, fade-in 1s ease-out;
    animation-timing-function: ease-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  .works-link .works-filtered-number {
    animation: slide 0.15s ease-out, fade-in 1s ease-out;
    animation-timing-function: ease-out;
    opacity: 0.5; }
  .works-link.active {
    background: white;
    box-shadow: 0 0 14px 0 #E7E7E7; }
    .works-link.active .show-on-hover {
      display: none; }
    .works-link.active .works-filtered-number {
      display: flex;
      opacity: 1; }
    .works-link.active.works-link-selected-hover:hover .works-filtered-number {
      display: none; }
    .works-link.active.works-link-selected-hover:hover .show-on-hover {
      display: flex; }
  .works-link:hover {
    box-shadow: 0 0 7px 0 #e7e7e7a6; }

@keyframes slide {
  0% {
    margin-left: -30%; }
  100% {
    margin-left: 0; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media (max-width: 699.98px) {
  .works-link {
    height: 35px;
    width: 100px;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    margin-right: 0; }
    .works-link:hover {
      box-shadow: 0 0 0 0; }
    .works-link.inside-filter {
      height: 50px;
      padding-left: 10px;
      width: 115px; }
      .works-link.inside-filter.selected {
        background: #f6f7f8;
        box-shadow: none; } }

html {
  width: 100vw;
  overflow-x: hidden; }

.main-container {
  margin-left: 0px;
  transition: all 0.2s ease-in-out;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.sidenav-open .sidenav {
  left: 0 !important; }

.sidenav-open .main-container {
  margin-left: 100px; }

.modal-open {
  overflow: hidden;
  height: 100vh;
  width: 100vw; }
  .modal-open .modal-background {
    background-color: rgba(0, 0, 0, 0.194);
    transition: 0.2s; }

.login-page {
  background: #00366A;
  width: 100%;
  height: 100vh; }
  .login-page .main-container {
    max-height: 100vh; }

@media (min-width: 700px) and (max-width: 1119.98px) {
  .sidenav-open .sidenav {
    left: 100px !important;
    z-index: 1032 !important; }
  .sidenav-open .main-container {
    margin-left: 0; } }

@media (max-width: 699.98px) {
  .sidenav-open .sidenav {
    left: 0 !important;
    width: 100%;
    height: 100%; }
  .sidenav-open .main-container {
    margin-left: 0; } }

.building-contract {
  border: 2px solid #ced4da;
  padding: 6px;
  margin-bottom: 12px;
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 2px; }
  .building-contract:hover {
    border: 2px solid #dedede; }
  .building-contract.selected {
    border: 2px solid #3CB5AD; }

.building-contract-sm {
  border: 2px solid #ced4da;
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  margin-left: 0.5rem;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  min-width: 100px; }
  .building-contract-sm h6 {
    font-size: 0.8rem;
    color: #5E6478; }
  .building-contract-sm:hover {
    border: 2px solid #5E6478; }
    .building-contract-sm:hover h6 {
      color: #00366A; }
  .building-contract-sm.selected {
    background-color: #3CB5AD;
    border: 2px solid #3CB5AD; }
    .building-contract-sm.selected h6 {
      color: white; }
  .building-contract-sm:first-child {
    margin-left: 0; }

@media (min-width: 1120px) and (max-width: 1249.98px) {
  .building-contract-sm {
    padding: 4px;
    min-width: 50px; }
    .building-contract-sm h6 {
      font-size: 0.7rem; } }

.w-h-match-parent {
  margin: 0 !important;
  padding: 0 !important; }

.custom-selection {
  width: 100%;
  margin-right: 10px;
  justify-content: center;
  margin-top: 0; }
  .custom-selection:last-child {
    margin-right: 0; }

.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block !important; }

.modal-bg {
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background: rgba(0, 0, 0, 0.5); }

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1020; }

.sticky-navbar {
  position: sticky;
  position: -webkit-sticky;
  top: 60px;
  z-index: 1020; }

.dropdown-menu {
  padding: 0;
  margin: 0.3rem 0 0;
  box-shadow: 0 2px 10px 0 rgba(68, 75, 100, 0.21);
  border: 1px solid #dadada26; }
  .dropdown-menu li .dropdown-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.3rem;
    padding-right: 2rem; }
    .dropdown-menu li .dropdown-item:first-child:hover {
      border-top-left-radius: 2px;
      border-top-left-radius: 2px; }
    .dropdown-menu li .dropdown-item:last-child:hover {
      border-bottom-left-radius: 2px;
      border-bottom-left-radius: 2px; }
    .dropdown-menu li .dropdown-item:active {
      background-color: #eaeaea; }
    .dropdown-menu li .dropdown-item i {
      margin-right: 1rem;
      width: 20px; }
  .dropdown-menu li.dropdown-divider {
    margin: 0; }

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1050;
  display: flex;
  align-items: baseline; }

.new-form-control {
  border: 2px solid #ced4da;
  height: calc(1.8em + 0.8rem + 2px); }

.modal-container {
  position: absolute;
  left: 0;
  right: 0;
  width: 600px;
  margin: 50px auto;
  margin-top: 27px;
  box-sizing: border-box;
  z-index: 1050;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  background: #fff;
  border: 0.5px solid #E8E8E8; }

.alert-text {
  width: 100% !important;
  height: 100% !important;
  margin: auto;
  position: absolute; }

.tooltip-row-error {
  background-color: #f29824; }

.react-datepicker-popper {
  z-index: 1030 !important; }

.react-datepicker-popper-modal {
  z-index: 2000 !important; }

.baserow {
  border: 2px solid #ccc;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; }
  .baserow .baserow-title {
    font-size: 12px;
    text-align: center; }
  .baserow.selected {
    background: #34394C;
    border: 2px solid #34394C;
    color: white; }
  .baserow:hover {
    background: #ccc;
    border: 2px solid #ccc; }
  .baserow:focus {
    background: #a2a2a2; }

.selectable-baserow {
  display: flex;
  flex-direction: row;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  margin-left: 50px; }
  .selectable-baserow .selectable-baserow-number {
    min-width: 80px;
    max-width: 80px;
    display: flex; }
  .selectable-baserow:hover {
    background: #34394c33;
    color: black; }
  .selectable-baserow.selected {
    background: #34394c1a;
    border-radius: 2px; }
    .selectable-baserow.selected .selectable-baserow-number {
      font-weight: bold; }
    .selectable-baserow.selected .selectable-baserow-text {
      font-weight: bold; }

.cell-placeholder {
  color: #868A97; }

.contract-footer {
  width: 100vw;
  height: 60px;
  background: white;
  position: fixed;
  bottom: 0;
  box-shadow: 0 0 14px 0 #0000001f;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  align-items: center;
  z-index: 1;
  margin-left: -20px; }

.sum-row td {
  background-color: #F6F8FA;
  text-align: center;
  font-weight: 500 !important; }

.sum-date-input {
  padding: 5px !important;
  padding-right: 40px !important;
  height: 30px !important;
  text-align: right;
  font-style: italic;
  border-width: 1px !important; }

.expanding-textarea {
  min-height: 24px;
  box-sizing: content-box;
  width: calc(100% - 1.75rem);
  line-height: 1.25; }

.notification-preview-iframe {
  width: 100%;
  height: 100%;
  min-height: 475px; }
