.disable-scroll {
  overflow-y: hidden; }

.details-spinner {
  margin-left: 50%;
  margin-top: 150px; }

.not-found {
  display: inline-block;
  margin-left: 42%;
  margin-top: 110px; }

.field-content-right-align {
  text-align: right; }
  .field-content-right-align input {
    text-align: right; }

.dropup .caret {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.fixed-table-toolbar {
  display: none; }

.box-typical {
  margin: 0 0 10px; }
  .box-typical.toolbar .fixed-table-toolbar {
    display: block; }

.no-wrap {
  white-space: nowrap; }

.form-group .form-control-label {
  padding: .375rem .75rem;
  margin-bottom: 0; }

.table-responsive {
  overflow: visible; }
  .table-responsive .bootstrap-table {
    overflow-y: visible; }

.main-container {
  margin-left: 0;
  transition: all 0.2s ease-in-out;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.sidenav-open .sidenav {
  left: 0 !important; }

.sidenav-open .main-container {
  margin-left: 100px; }

.modal-open {
  overflow: hidden;
  height: 100vh;
  width: 100vw; }
  .modal-open .modal-background {
    background-color: rgba(0, 0, 0, 0.194);
    transition: 0.2s; }

.login-page {
  background: #00366A;
  width: 100%;
  height: 100vh; }
  .login-page .main-container {
    max-height: 100vh; }

@media (min-width: 700px) and (max-width: 1119.98px) {
  .sidenav-open .sidenav {
    left: 100px !important;
    z-index: 1032 !important; }
  .sidenav-open .main-container {
    margin-left: 0; } }

@media (max-width: 699.98px) {
  .sidenav-open .sidenav {
    left: 0 !important;
    width: 100%;
    height: 100%; }
  .sidenav-open .main-container {
    margin-left: 0; } }

.building-contract {
  border: 2px solid #ced4da;
  padding: 6px;
  margin-bottom: 12px;
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 2px; }
  .building-contract:hover {
    border: 2px solid #dedede; }
  .building-contract.selected {
    border: 2px solid #3CB5AD; }

.building-contract-sm {
  border: 2px solid #ced4da;
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  margin-left: 0.5rem;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  min-width: 100px; }
  .building-contract-sm h6 {
    font-size: 0.8rem;
    color: #5E6478; }
  .building-contract-sm:hover {
    border: 2px solid #5E6478; }
    .building-contract-sm:hover h6 {
      color: #00366A; }
  .building-contract-sm.selected {
    background-color: #3CB5AD;
    border: 2px solid #3CB5AD; }
    .building-contract-sm.selected h6 {
      color: white; }
  .building-contract-sm:first-child {
    margin-left: 0; }

@media (min-width: 1120px) and (max-width: 1249.98px) {
  .building-contract-sm {
    padding: 4px;
    min-width: 50px; }
    .building-contract-sm h6 {
      font-size: 0.7rem; } }

.custom-selection {
  width: 100%;
  margin-right: 10px;
  justify-content: center;
  margin-top: 0; }
  .custom-selection:last-child {
    margin-right: 0; }
