.role {
  margin-bottom: 1rem; }
  .role .role-img {
    background: #ced4da;
    height: 40px;
    border-radius: 2px; }
  .role .btn-role-selection {
    color: #5E6478;
    text-decoration: underline;
    background: transparent; }
    .role .btn-role-selection:active {
      border: 0; }
  .role:hover .role-img {
    background: #34394C; }
  .role:hover .btn-role-selection {
    border: 0;
    color: #34394C; }
  .role.current .role-img {
    background: #34394C; }
  .role.current .btn-role-selection {
    color: #34394C; }
